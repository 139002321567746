/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-10-21",
    versionChannel: "nightly",
    buildDate: "2023-10-21T00:20:29.571Z",
    commitHash: "94174b30fed8e2d0762374238cc3af54701335b1",
};
